<template>
  <div class="paginator">
    <div class="paginator__total-pages">
      <span>{{ showinMin }}-{{ showinMax }}</span>
      <span>{{ $t('of') }}</span>
      <span>{{ totalRows.toLocaleString() }}</span>
    </div>
    <div class="paginator__controls">
      <div class="prev" :class="{'disabled' : !hasPrev}" @click="prev">
        <i class="ez-icon-chevron-down"/>
      </div>
      <div class="next" :class="{'disabled' : !hasNext}" @click="next">
        <i class="ez-icon-chevron-down"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Paginator",

  props: {
    totalRows: Number,
    perPage: Number,
    currentPage: Number,
    onNext: Function,
    onPrev: Function
  },

  computed: {
    showinMin() {
      return (this.currentPage - 1) * this.perPage + 1;
    },

    showinMax() {
      const last_page = (this.totalRows / this.perPage) <= this.currentPage;
      return last_page ? this.totalRows : this.showinMin + this.perPage - 1;
    },

    hasPrev() {
      return this.currentPage > 1 && this.totalRows >= this.perPage;
    },

    hasNext() {
      if ( this.totalRows <= this.perPage ) return false;
      const totalPages = this.totalRows / this.perPage;
      return this.currentPage < totalPages;
    },
  },

  methods: {
    prev() {
      if ( this.hasPrev && this.onPrev ) {
        this.onPrev();

        this.$router.replace({
          query: {
            ...this.$route.query,
            currentPage: this.currentPage - 1,
            perPage: this.perPage,
          }
        });
      }
    },

    next() {
      if ( this.hasNext && this.onNext ) {
        this.onNext();

        this.$router.replace({
          query: {
            ...this.$route.query,
            currentPage: this.currentPage + 1,
            perPage: this.perPage,
          }
        });
      }
    }
  },

  watch: {
    perPage(perPage) {
      this.$router.replace({ query: { ...this.$route.query, perPage } });
    }
  }
}
</script>

<style lang="scss" scoped>
.paginator {
  display: flex;
  align-items: center;

  &__total-pages {
    margin-right: 20px;

    span {
      color: color(bg-blue-dark);
      font-weight: $font-medium;

      &:nth-of-type(2) {
        margin: 0 10px;
      }
    }
  }

  &__controls {
    display: flex;

    .prev,
    .next {
      background: color(bg-white);
      border-radius: 3px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: color(bg-primary);
      border: 1px solid color(border);

      i {
        color: color(bg-primary);
        font-size: 1.3rem;
      }

      &.disabled {
        background: none;
        border-color: color(border);
        cursor: no-drop;

        i {
          color: color(bg-gray2);
        }

      }

      &:not(.disabled):hover {
        background: color(bg-primary);

        i {
          color: white;
        }
      }
    }

    .prev {
      i {
        transform: rotate(90deg);
      }
    }

    .next {
      margin-left: 10px;

      i {
        transform: rotate(-90deg);
      }
    }
  }
}
</style>

<i18n scoped>
{
  "en": {
    "of": "of"
  },
  "es": {
    "of": "de"
  }
}
</i18n>