export default [
  {
    path: 'inventory/cars',
    name: 'InventoryList',
    component: () => import(/* webpackChunkName: "inventory_list" */ '@/views/Inventory/Cars/List/index.vue'),
    meta: {
      requiresAuth: true,
      inventory: true,
      title: 'inventory_cars_title'
    },
    children: [
      {
        path: 'details/:vin',
        name: 'InventoryDetails',
        component: () => import(/* webpackChunkName: "inventory_details" */ '@/views/Inventory/Cars/Details/index.vue'),
        meta: {
          requiresAuth: true,
          inventory: true,
          title: 'inventory_details_title'
        },
        props: true,
      }
    ]
  }
]