var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"textbox",class:{ 'form-group--error': _vm.validator && _vm.validator.$error },style:({
                  '--bg': _vm.bg,
                  '--width': _vm.width,
                  '--height': _vm.height,
                  '--border': _vm.border,
                  '--border-color': _vm.borderColor,
                  '--border-radius': _vm.borderRadius,
                  '--padding': _vm.padding,
                  '--box-shadow': _vm.boxShadow,
                  '--font-size': _vm.fSize,
                  '--font-color': _vm.fColor,
                  '--placeholder-color': _vm.phColor,
                  '--placeholder-size': _vm.phFSize,
              })},[(_vm.label)?_c('label',[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),(_vm.type !== 'tel')?_c('input',{directives:[{name:"lowercase",rawName:"v-lowercase",value:(_vm.lowercase),expression:"lowercase"},{name:"uppercase",rawName:"v-uppercase",value:(_vm.uppercase),expression:"uppercase"}],ref:"textbox",attrs:{"autocomplete":_vm.autocomplete || 'off',"disabled":_vm.disabled,"max":_vm.max,"min":_vm.min,"placeholder":_vm.placeholder,"step":_vm.step,"type":_vm.type},domProps:{"value":_vm.value},on:{"input":_vm.updateText,"keydown":function($event){return _vm.$emit('keydown', $event)},"keyup":function($event){return _vm.$emit('keyup')}}}):_c('the-mask',_vm._b({ref:"textbox",attrs:{"autocomplete":_vm.autocomplete || 'off',"disabled":_vm.disabled,"masked":true,"placeholder":_vm.placeholder || '(###) ###-####',"value":_vm.value,"mask":"(###) ###-####","type":"tel"},on:{"input":_vm.updateTel}},'the-mask',_vm.$attrs,false)),(_vm.error)?_c('span',{staticClass:"has-error animated headShake"},[_vm._v(_vm._s(_vm.$t(_vm.error)))]):_vm._e(),(_vm.isLoading)?_c('span',{staticClass:"is-loading"},[_c('Spinner',{attrs:{"color":"#221ecd","size":"1.4rem"}})],1):_vm._e(),(_vm.validator && _vm.validator.$error && _vm.validator.hasOwnProperty('required'))?_c('div',[(!_vm.validator.required)?_c('span',{staticClass:"has-error animated headShake"},[_vm._v(_vm._s(_vm.$t('required_field')))]):_vm._e()]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }