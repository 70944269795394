export const initialState = {
  user: null,
  userIsIdle: false,
  userLockedCustomers: [],
  companyPermissions: null,
  impersonate: null,
  loading: [],
  isLoggedIn: false,
  isConnected: false,
  socketMessage: '',
  unreadNotifications: [],
  chatRequests: [],
  chatBotRequests: [],
  transferRequests: [],
  chatGroups: [],
  archivedChatGroups: [],
  optoutChatGroups: [],
  botChatGroups: [],
  callRequests: [],
  callRequestsHold: [],
  customerNotifications: [],
  newLeads: [],
  mentions: [],
  smsRequests: [],
  apptReminders: [],
  financeApplications: [],
  newWorkPlan: [],
  callQueues: [],
  onCall: false,
  callSid: null,
  callTime: 0,
  poll_email_uid: null,
  callConnection: null,
  companySettings: null,
  routeTitle: '',
  downloading: false,
  uploading: false,
  uploadImportFileData: null,
  uploadImportFileStep: 'Upload',
  settingsLinks: [],
  carPaginationDetails: null,
  isInternalChatVisible: false,
  internalChatUnread: {total: 0, group: 0, dm: 0},
  internalChatMessages: [],
};

export const state = {
  ...initialState,
  ...{
    isLoggedIn: !!localStorage.getItem('token'),
    user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
    companyPermissions: localStorage.getItem('user') ? {
      permissionPlan: JSON.parse(localStorage.getItem('user')).Company?.permissionPlan,
      permissions: JSON.parse(localStorage.getItem('user')).Company?.permissions,
    } : null,
    carPaginationDetails: localStorage.getItem('car_pag_details') ? JSON.parse(localStorage.getItem('car_pag_details')) : null,
    settingsLinks: localStorage.getItem('settings_links') ? JSON.parse(localStorage.getItem('settings_links')) : [],
    impersonate: localStorage.getItem('impersonate') ? localStorage.getItem('impersonate') : null,
  },
};